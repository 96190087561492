import React from 'react';
import Landing from '../../Components/2-Landing';
import NuevosIngresos from '../../Components/2.1-NuevosIngresos';
import Contact from '../../Components/2.2-Contact';
import QuienSomos from '../../Components/2.3-Quienes-somos';
import WhatsappIcon from '../../Components/0-WhatsappIcon';

const Home = () => {
  return (
    <div>
      <WhatsappIcon/>
      <div id="landing" >
        <Landing/>
      </div>
      <NuevosIngresos />
      <div id="quienes-somos">
        <QuienSomos />
      </div>
      <div id="contact">
        <Contact />
      </div>
    </div>
  );
};

export default Home;
