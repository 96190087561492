import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './navbar.css';
import Logo from '../../Assets/images/Logos/DincarLogo.png';

function NavBar() {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleNavigation = (path, sectionId) => {
    if (location.pathname === path) {
      scrollToSection(sectionId);
    } else {
      navigate(path);
      setTimeout(() => scrollToSection(sectionId), 100);
    }
    setMenuOpen(false);
  };

  const CloseMenu = () => {
    setMenuOpen(false)
  }

  return (
    <div >
      <div onClick={CloseMenu} className={`navbar-backdrop ${menuOpen ? 'open' : ''}`}/>
      <Navbar bg="light" expand="lg" className="navbar-custom">
        <img onClick={() => handleNavigation('/', 'landing')} className="Navbar-logo" alt="" src={Logo} />
        <Container className='Navbar-Container'>
          <Navbar.Toggle
            onClick={() => setMenuOpen(!menuOpen)}
            aria-controls="basic-navbar-nav"
            className="Toggle"
          />
          <Navbar.Collapse
            in={menuOpen}
            id="basic-navbar-nav"
            className="navbar-collapse"
            expanded={menuOpen}
          >
            <Nav className="me-auto" style={{ fontSize: '13px' }}>
              <Nav.Link className="product-link"  onClick={() => handleNavigation('/nuestros-autos', 'Cars')}>
                NUESTROS AUTOS
              </Nav.Link>
              <Nav.Link className="product-link" onClick={() => handleNavigation('/', 'quienes-somos')}>
                QUIENES SOMOS
              </Nav.Link>
              <Nav.Link className="product-link" onClick={() => handleNavigation('/', 'contact')}>
                CONTACTO
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default NavBar;