import './cars.css';
import React, { useEffect, useState } from 'react';
import { IoChevronBackSharp, IoChevronForwardSharp, IoChevronDown, IoChevronUp } from 'react-icons/io5';
import { IoOptionsSharp } from "react-icons/io5";
import { MdOutlineClose } from "react-icons/md";
import { IoSearchSharp } from "react-icons/io5";
import Loading from '../0-Loading';
import NotFound from '../0-NotFound';
import Cars from '../../Content/Cars';
import CarsCards from './CarsCard/index';





const OurCars = () => {
  
  const [isLoading, setIsLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showCleanButton, setShowCleanButton] = useState(false);
  const [activeFilters, setActiveFilters] = useState({
    precio: false,
    kilometros: false,
    ano: false,
    marcaOrden: false,
    marca: false,
    transmision: false,
    puertas: false,
    carroceria: false,
    litros: false,
    combustible: false,
  });
  const [selectedFilters, setSelectedFilters] = useState({
    precio: "",
    kilometros: "",
    ano: "",
    marcaOrden: "",
    marca: [],
    transmision: [],
    puertas: [],
    carroceria: [],
    litros: [],
    combustible: [],
  });
  
  
  //-------------Funciones Auxiliares----------------
  
  
  const sortByMarca = (cars, order = 'asc') => {
    return [...cars].sort((a, b) => {
      const nameA = a.Marca.toLowerCase();
      const nameB = b.Marca.toLowerCase();
      return order === 'asc' 
      ? nameA.localeCompare(nameB) 
      : nameB.localeCompare(nameA);
    });
  };
  
  
  const sortedCarsAsc = sortByMarca(Cars, "asc");
  const [filteredCars, setFilteredCars] = useState(sortedCarsAsc);


  const parsePrice = (priceString) => {
    let price = 0;
    if (priceString.startsWith("USD")) {
      const amount = parseFloat(priceString.replace('USD', '').replace('.', '').trim());
      price = amount * 1.21;
    } else if (priceString.startsWith("$")) {
      price = parseFloat(priceString.replace('$', '').replace('.', '').trim());
    }
    return price || 0;
  };
  
  const getPriority = (car) => {
    if (car.Reservado === "No" && car.Vendido === "No") return 1;
    if (car.Reservado === "Sí" && car.Vendido === "No") return 2;
    if (car.Vendido === "Sí") return 3;
    return 4;
  };

  //--------------------UseEffect-----------------------

  useEffect(() => {
    const searchTermLower = searchTerm.toLowerCase();
    const searchWords = searchTermLower.split(" ").filter(Boolean); // Divide la búsqueda en palabras.
  
    const filtered = Cars.filter((car) => {
      const combineSearch = `${car.Marca} ${car.Modelo} ${car.Ano} ${car.Color}`.toLowerCase();
  
    
      return searchWords.every((word) => 
        combineSearch.split(" ").some((field) => field.startsWith(word))
      );
    });
  
    setFilteredCars(filtered);
    setNotFound(filtered.length === 0);
  }, [searchTerm]);
  


  useEffect(() => {
    applyFilters();
  }, [selectedFilters]);


  useEffect(() => {
    if (showFilterMenu) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [showFilterMenu]);

  //-----------------Logica de integracion-------------

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    setShowCleanButton(value !== '');
  };


  const toggleFilter = (filter) => {
    setActiveFilters((prev) => ({
      ...prev,
      [filter]: !prev[filter],
    }));
  };


  const handleCheckboxChange = (key, value) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [key]: prev[key].includes(value)
        ? prev[key].filter((item) => item !== value)
        : [...prev[key], value],
    }));
  };


  const handleFilterChange = (key, value) => {
    if (key === 'precio' || key === 'kilometros' || key === 'ano') {
      setSelectedFilters((prev) => ({
        ...prev,
        precio: key === 'precio' ? value : '',
        kilometros: key === 'kilometros' ? value : '',
        ano: key === 'ano' ? value : '',
      }));
    } else {
      setSelectedFilters((prev) => ({
        ...prev,
        [key]: value,
      }));
    }
  };

  
  const applyFilters = () => {
    let filtered = [...Cars];
  
    // Transformar datos: añadir precio en pesos
    filtered = filtered.map((car) => ({
      ...car,
      precioPesos: parsePrice(car.Precio),
      kilometros: parseInt(car.Kilometros.replace(/[^0-9]/g, ''), 10) || 0,
      ano: parseInt(car.Ano, 10) || 0,
    }));
  
    // Aplicar filtros
    if (selectedFilters.marca.length > 0) {
      filtered = filtered.filter((car) =>
        selectedFilters.marca.some(
          (selectedMarca) => car.Marca.toLowerCase() === selectedMarca.toLowerCase()
        )
      );
    }
  
    if (selectedFilters.transmision.length > 0) {
      filtered = filtered.filter((car) =>
        selectedFilters.transmision.includes(car.Transmision)
      );
    }
  
    if (selectedFilters.puertas.length > 0) {
      filtered = filtered.filter((car) =>
        selectedFilters.puertas.includes(car.Puertas)
      );
    }
  
    if (selectedFilters.carroceria.length > 0) {
      filtered = filtered.filter((car) =>
        selectedFilters.carroceria.includes(car.Carroceria)
      );
    }
  
    if (selectedFilters.litros.length > 0) {
      filtered = filtered.filter((car) =>
        selectedFilters.litros.includes(car.Litros)
      );
    }
  
    if (selectedFilters.combustible.length > 0) {
      filtered = filtered.filter((car) =>
        selectedFilters.combustible.some(filter => car.Combustible.includes(filter))
      );
    }
  
    // Aplicar orden
    filtered.sort((a, b) => {
      // Prioridad
      const priorityA = getPriority(a);
      const priorityB = getPriority(b);
      if (priorityA !== priorityB) return priorityA - priorityB;
  
      // Orden dinámico
      if (selectedFilters.precio) {
        return selectedFilters.precio === "asc"
          ? a.precioPesos - b.precioPesos
          : b.precioPesos - a.precioPesos;
      }
  
      if (selectedFilters.kilometros) {
        return selectedFilters.kilometros === "asc"
          ? a.kilometros - b.kilometros
          : b.kilometros - a.kilometros;
      }
  
      if (selectedFilters.ano) {
        return selectedFilters.ano === "asc"
          ? a.ano - b.ano
          : b.ano - a.ano;
      }
  
      // Orden por marca y modelo si no se especifica otro orden
      const marcaA = a.Marca.toLowerCase();
      const marcaB = b.Marca.toLowerCase();
      if (marcaA !== marcaB) return marcaA.localeCompare(marcaB);
      return a.Modelo.toLowerCase().localeCompare(b.Modelo.toLowerCase());
    });
  
    setFilteredCars(filtered);
    setNotFound(filtered.length === 0);
  
    console.log('Selected Filters:', selectedFilters);
    console.log('Filtered Cars After Sort:', filtered);
  };


  const clearFilters = () => {

    setFilteredCars(sortByMarca(Cars, 'asc')); 
    setSearchTerm("");
    setShowCleanButton(false);

    setSelectedFilters({
      precio: '',
      kilometros: '',
      ano: '',
      marcaOrden: '',
      marca: [],
      transmision: [],
      puertas: [],
      carroceria: [],
      litros: [],
      combustible: [],
    });
  
    setActiveFilters({
      precio: false,
      kilometros: false,
      ano: false,
      marcaOrden: false,
      marca: false,
      transmision: false,
      puertas: false,
      carroceria: false,
      litros: false,
      combustible: false,
    });
  
    const checkboxes = document.querySelectorAll('.filter-menu-content input[type="checkbox"]');
    checkboxes.forEach((checkbox) => (checkbox.checked = false));
  };

  const preventScrollPropagation = (e) => {
    const target = e.target;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;
  
    if ((scrollTop === 0 && e.deltaY < 0) || (scrollTop + clientHeight >= scrollHeight && e.deltaY > 0)) {
      e.preventDefault();
    }
  };

  return (
    <div className="Ourcars-Components" id='Cars'>
      {showFilterMenu && (
        <div
          className="filter-menu-backdrop"
          onClick={() => setShowFilterMenu(false)}
        >
        </div>
      )}
      <div className='Filter-Nav-Component'>
        <div className='filter-Nav-Container'>
          <button onClick={() => setShowFilterMenu(true)}><IoOptionsSharp size={25} /> Filtrar <IoChevronDown size={15} /></button>
        </div>
      </div>
      <div 
        className={`filter-menu ${showFilterMenu ? 'show' : ''}`}
        onWheel={preventScrollPropagation}
      >
        <div
          className="filter-menu-handle"
          onClick={() => setShowFilterMenu(!showFilterMenu)}
        >
          {showFilterMenu ? (
            <button><IoOptionsSharp size={25} /> <IoChevronForwardSharp size={20} /></button>
          ) : (
            <button><IoChevronBackSharp size={20} /><IoOptionsSharp size={25} /></button>
          )}
        </div>

        <div className='Close-Filter-Menu-Container'>  
          <button className='Close-Backdrop-Button' onClick={() => setShowFilterMenu(false)}>
            <MdOutlineClose size={27} />
          </button>
        </div>

        <div className="filter-menu-content">
          <div className='filter-menu-Order-Container'>
            <div className='Ordenar-Title-Container'>
              <h5>Ordenar Por</h5>
            </div>

            <div className="Order-group">
              <p onClick={() => toggleFilter('precio')}>
                Precio {activeFilters.precio ? <IoChevronUp /> : <IoChevronDown />}
              </p>
              {activeFilters.precio && (
                <div className="Order-buttons">
                  <button 
                    className={selectedFilters.precio === 'asc' ? 'active' : ''}
                    onClick={() => {handleFilterChange('precio', 'asc')}}
                  >
                    Menor Precio
                  </button>
                  <button 
                    className={selectedFilters.precio === 'desc' ? 'active' : ''}
                    onClick={() => { handleFilterChange('precio', 'desc')}}
                  >
                    Mayor Precio
                  </button>
                </div>
              )}
            </div>

            <div className="Order-group">
              <p onClick={() => toggleFilter('kilometros')}>
                Kilómetros {activeFilters.kilometros ? <IoChevronUp /> : <IoChevronDown />}
              </p>
              {activeFilters.kilometros && (
                <div className="Order-buttons">
                  <button 
                    className={selectedFilters.kilometros === 'asc' ? 'active' : ''}
                    onClick={() => { handleFilterChange('kilometros', 'asc')}}
                  >
                    Menos Km
                  </button>
                  <button 
                    className={selectedFilters.kilometros === 'desc' ? 'active' : ''}
                    onClick={() => { handleFilterChange('kilometros', 'desc')}}
                  >
                    Más Km
                  </button>
                </div>
              )}
            </div>

            <div className="Order-group">
              <p onClick={() => toggleFilter('ano')}>
                Año {activeFilters.ano ? <IoChevronUp /> : <IoChevronDown />}
              </p>
              {activeFilters.ano && (
                <div className="Order-buttons">
                  <button 
                    className={selectedFilters.ano === 'asc' ? 'active' : ''}
                    onClick={() => { handleFilterChange('ano', 'asc')}}
                  >
                    Más Años
                  </button>
                  <button 
                    className={selectedFilters.ano === 'desc' ? 'active' : ''}
                    onClick={() => { handleFilterChange('ano', 'desc')}}
                  >
                    Menos Años
                  </button>
                </div>
              )}
            </div>

          </div>


            {/* Repetir para Kilómetros y Año */}
          <div className="filter-menu-Filter-Container">
            
            <div className='Filter-Title-Container'>
              <h5>Filtrar Por</h5>
            </div>

            <div className="filter-group">
              <p onClick={() => toggleFilter('marca')}>
                Marca {activeFilters.marca ? <IoChevronUp /> : <IoChevronDown />}
              </p>
              {activeFilters.marca && (
                <div className='Marcas-Checkbox'>
                  {['Alfa Romeo', 'Audi', 'BMW', 'Chery', 'Chevrolet', 'Citröen', 'Fiat', 'Ford', 'Honda', 'Hyundai', 'Jeep', 'Kia', 'Mercedes-Benz', 'Nissan', 'Peugeot', 'Dodge', 'Renault', 'Suzuki', 'Toyota', 'Volkswagen' ].map((marca) => (
                    <label key={marca}>
                      {marca}
                      <input
                        type="checkbox"
                        onChange={() => handleCheckboxChange('marca', marca)}
                      />
                    </label>
                  ))}
                </div>
              )}
            </div>

            <div className="filter-group">
              <p onClick={() => toggleFilter('transmision')}>
                Transmision {activeFilters.transmision ? <IoChevronUp /> : <IoChevronDown />}
              </p>
              {activeFilters.transmision && (
                <div className='Transmision-Checkbox'>
                  {['Automatica', 'Manual' ].map((transmision) => (
                    <label key={transmision}>
                      {transmision}
                      <input
                        type="checkbox"
                        onChange={() => handleCheckboxChange('transmision', transmision)}
                      />
                    </label>
                  ))}
                </div>
              )}
            </div>

            <div className="filter-group">
              <p onClick={() => toggleFilter('puertas')}>
                Puertas {activeFilters.puertas ? <IoChevronUp /> : <IoChevronDown />}
              </p>
              {activeFilters.puertas && (
                <div className='Puertas-Checkbox'>
                  {['2', '3', '4', '5' ].map((puertas) => (
                    <label key={puertas}>
                      {puertas} Puertas
                      <input
                        type="checkbox"
                        onChange={() => handleCheckboxChange('puertas', puertas)}
                      />
                    </label>
                  ))}
                </div>
              )}
            </div>

            <div className="filter-group">
              <p onClick={() => toggleFilter('carroceria')}>
                Carroceria {activeFilters.carroceria ? <IoChevronUp /> : <IoChevronDown />}
              </p>
              {activeFilters.carroceria && (
                <div className='Puertas-Checkbox'>
                  {['Auto', 'Suv', 'Pick Up', 'Furgon' ].map((carroceria) => (
                    <label key={carroceria}>
                      {carroceria}
                      <input
                        type="checkbox"
                        onChange={() => handleCheckboxChange('carroceria', carroceria)}
                      />
                    </label>
                  ))}
                </div>
              )}
            </div>

            <div className="filter-group">
              <p onClick={() => toggleFilter('litros')}>
                Cilindrada (Litros) {activeFilters.litros ? <IoChevronUp /> : <IoChevronDown />}
              </p>
              {activeFilters.litros && (
                <div className='Puertas-Checkbox'>
                  {['1.1', '1.2', '1.3','1.4','1.5', '1.6', '1.8', '2.0', '2.2' ].map((litros) => (
                    <label key={litros}>
                      {litros}
                      <input
                        type="checkbox"
                        onChange={() => handleCheckboxChange('litros', litros)}
                      />
                    </label>
                  ))}
                </div>
              )}
            </div>

            <div className="filter-group">
              <p onClick={() => toggleFilter('combustible')}>
                Combustible {activeFilters.combustible ? <IoChevronUp /> : <IoChevronDown />}
              </p>
              {activeFilters.combustible && (
                <div className='Puertas-Checkbox'>
                  {['Diesel', 'GNC', 'Nafta'].map((combustible) => (
                    <label key={combustible}>
                      {combustible}
                      <input
                        type="checkbox"
                        onChange={() => handleCheckboxChange('combustible', combustible)}
                      />
                    </label>
                  ))}
                </div>
              )}
            </div>

          </div>
        </div>

        <div className='Show-Results-button-Div'>
          <button onClick={() => setShowFilterMenu(false)} >Mostrar Resultados</button>
          
          <button 
            className="clear-filters-button"
            onClick={clearFilters}>
              Limpiar Filtros
            </button>
        </div>

      </div>

      <div className="Ourcars-Container">
          <span className='searchbar-container'>
            <div className="search-bar">
              <IoSearchSharp className='searchbaricon'/>

              <input
                className='searchBar'
                type="text"
                value={searchTerm}
                onChange={handleInputChange}
                placeholder="Busca por marca, modelo, año o color"
              />
              {/* {showCleanButton && (
                  <button className='cleanSearchButton' onClick={handleCleanSearch}>
                      X
                  </button>
              )} */}
            </div>


          </span>
        <div className="Information-OurCars-Container">

          
          {/* <h1 className="Title-OurCars">
            Nuestros <span>Autos</span>
          </h1> */}

          {isLoading ? (
            <div className="OurCars-loading-container">
              <Loading />
            </div>
          ) : notFound ? (
            <div className="OurCars-not-found">
              <NotFound />
            </div>
          ) : (
            <div className="Ourcars-Grid-Container">
              {filteredCars.map((car, index) => (
                <div key={index} className="OurCarsCards">
                  <CarsCards car={car} />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OurCars;
