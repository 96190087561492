import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import "./carscard.css";
import { slugify } from '../../../Helpers/slugify';
import { useEffect } from 'react';


const CarsCards = ({ car }) => {

    const handleNavigation = (car, sectionId) => {
        const slug = slugify(`${car.Marca}-${car.Modelo}`, { lower: true });
        const path = `/auto/${slug}`;
      
        if (location.pathname === path) {
          scrollToSection(sectionId);
        } else {
          navigate(path, { state: { id: car.id } }); // Pasar el ID en el state
          const interval = setInterval(() => {
            const section = document.getElementById(sectionId);
            if (section) {
              clearInterval(interval);
              section.scrollIntoView({ behavior: 'smooth' });
            }
          }, 100);
        }
      };
      
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    const navigate = useNavigate();
    const location = useLocation();

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };


    return (
        <div className="Carscard-container">
            <div className="Carscard-information">
                {car.Reservado === "Si" && car.Vendido === "No" && (<div className="Car-Reservado"><p>Reservado</p></div>)}
                {car.Reservado === "No" && car.Vendido === "Si" && (<div className="Car-Vendido"><p>Vendido</p></div>)}
                <img 
                    className="Carscard-Image" 
                    onClick={car.Reservado === "Si" || car.Vendido === "Si" 
                        ? null 
                        : () => handleNavigation(car, 'Detail')
                    }
                    src={car.ImagenPortada} 
                    alt="" 
                />
                <h4 className="Carscard-title-h4">{car.Marca} • {car.Modelo}</h4>
                <h5>{car.Ano} • {car.Kilometros}Km • {car.Transmision}</h5>
            </div>

            <div className="Precio-Car-Card">
                <p>{car.Precio}</p>
            </div>

            <div className="Button-Car-Card">
                <button 
                    disabled={car.Reservado === "Si" || car.Vendido === "Si"} 
                    onClick={car.Reservado === "Si" || car.Vendido === "Si" 
                    ? null 
                    : () => handleNavigation(car, 'Detail')}
                >
                    Ver Más
                </button>
            </div>

        </div>
    );
};

export default CarsCards;
