import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ScrollToTop from './Components/0-ScrollLocation';
import Navbar from './Components/1-Navbar/index';
import Home from './Pages/Home';
import OurCars from './Pages/OurCars';
import DetailsPage from './Pages/Details/index';


function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/nuestros-autos" element={<OurCars />} />
        <Route path="/auto/:slug" element={<DetailsPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

