import './notfound.css';
import React from 'react';
import Notfound from '../../Assets/images/NotFound/NotFound.png';

const NotFound = () => {
  return (
    <div className="NotFoundContainer">
      <h2>
        No se encontraron <span>Autos</span>
      </h2>
    </div>
  );
};

export default NotFound;
