import "./nuevosingresos.css";
import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Rightarrow from "../../Assets/images/Slider/Rightarrow.svg";
import Leftarrow from "../../Assets/images/Slider/Leftarrow.svg";
import CarsCards from "./NuevosIngresosCard/NuevosIngresosCard";
import Cars from "../../Content/Cars";


const NuevosIngresos = () => {

  const sliderSettings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000, 
    prevArrow: <img className="slick-next" src={Leftarrow} alt="Prev" />,
    nextArrow: <img className="slick-next" src={Rightarrow} alt="Next" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const FilterCars = Cars.filter(car => car.Vendido === 'No' && car.Reservado === "No");

  const latestCars = FilterCars.slice(-6);

  return (
    <div id="SolutionPage" className="Roles-Section">
      <div className="Roles-container">
        <div className="Roles-title">
          <h2><span>Nuevos</span> Ingresos</h2>
        </div>
        
        <div className="Slider-container">
          <Slider {...sliderSettings}>
            {latestCars.map((car, index) => (
              <div key={index} className="RolesCards">
                <CarsCards car={car} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default NuevosIngresos;
