import './cardetail.css';
import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Cars from '../../Content/Cars';
import { IoMdArrowRoundBack } from "react-icons/io";
import slugify from 'slugify';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";






const CarDetail = () => {

    const ExpandedImage = ({ images, initialImageIndex, onClose }) => {
        const [expandedImage, setExpandedImage] = useState(images[initialImageIndex]);
        const [currentIndex, setCurrentIndex] = useState(initialImageIndex);
        const [touchStartX, setTouchStartX] = useState(null);
    }    

    const [expandedImage, setExpandedImage] = useState(null);
    const [expandedImageIndex, setExpandedImageIndex] = useState(0);
    const [activeImageIndex, setActiveImageIndex] = useState(0); 
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [isGeneralVisible, setIsGeneralVisible] = useState(false);
    const [isSecurityVisible, setIsSecurityVisible] = useState(false);
    const [isComfortVisible, setIsComfortVisible] = useState(false);
    const [isDimensionsVisible, setIsDimensionsVisible] = useState(false);
    const [isPerformanceVisible, setIsPerformanceVisible] = useState(false);
    const [car, setCar] = useState(null);
    const [touchStartX, setTouchStartX] = useState(null);
    const navigate = useNavigate();
    const { slug } = useParams();

    

    useEffect(() => {
        const foundCar = Cars.find((car) =>
            slugify(`${car.Marca}-${car.Modelo}`).toLowerCase() === slug
        );
        setCar(foundCar || null);
    }, [slug]);

    useEffect(() => {
        const section = document.getElementById('Cars');
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);

    const handleNextImage = useCallback(() => {
        if (!car?.Imagen) return; // Verifica si car.Imagen existe
        const nextIndex = (expandedImageIndex + 1) % car.Imagen.length;
        setExpandedImage(car.Imagen[nextIndex]);
        setExpandedImageIndex(nextIndex);
    }, [expandedImageIndex, car?.Imagen]);

    const handlePrevImage = useCallback(() => {
        if (!car?.Imagen) return; // Verifica si car.Imagen existe
        const prevIndex = (expandedImageIndex - 1 + car.Imagen.length) % car.Imagen.length;
        setExpandedImage(car.Imagen[prevIndex]);
        setExpandedImageIndex(prevIndex);
    }, [expandedImageIndex, car?.Imagen]);

    useEffect(() => {
        if (!expandedImage) return;
    
        const handleKeyDown = (event) => {
            if (event.key === "ArrowRight") handleNextImage();
            if (event.key === "ArrowLeft") handlePrevImage();
        };
    
        document.addEventListener("keydown", handleKeyDown);
        return () => document.removeEventListener("keydown", handleKeyDown);
    }, [expandedImage, handleNextImage, handlePrevImage]);

    useEffect(() => {
        if (!expandedImage) return;
    
        const handleTouchStart = (event) => {
            setTouchStartX(event.touches[0].clientX);
        };
    
        const handleTouchEnd = (event) => {
            if (touchStartX === null) return;
    
            const touchEndX = event.changedTouches[0].clientX;
            const swipeDistance = touchEndX - touchStartX;
            const SWIPE_THRESHOLD = 50;
    
            if (swipeDistance > SWIPE_THRESHOLD) handlePrevImage();
            if (swipeDistance < -SWIPE_THRESHOLD) handleNextImage();
    
            setTouchStartX(null);
        };
    
        document.addEventListener("touchstart", handleTouchStart);
        document.addEventListener("touchend", handleTouchEnd);
    
        return () => {
            document.removeEventListener("touchstart", handleTouchStart);
            document.removeEventListener("touchend", handleTouchEnd);
        };
    }, [expandedImage, touchStartX, handleNextImage, handlePrevImage]);

    if (!car) {
        return <div>Auto no encontrado</div>;
    }

    const toggleVisibility = (setter) => {
        setter((prev) => !prev);
    };

    const CarsClick = () => {
        navigate('/nuestros-autos');
    };

    const handleWhatsappClick = () => {
        const message = `Hola, ¿cómo estás? Quería consultarte por el ${car.Marca} ${car.Modelo} que vi en su página.`;
        const encodedMessage = encodeURIComponent(message);
        const phone = "5491124096499"; 
        window.open(`https://wa.me/${phone}?text=${encodedMessage}`, "_blank");
    };

    const handleExpandImage = (index) => {
        if (!car?.Imagen) return;
        setExpandedImage(car.Imagen[index]);
        setExpandedImageIndex(index);
        setIsModalOpen(true);
    };

    const handleCloseImage = () => {
        setExpandedImage(null);
    };

    const handleThumbnailClick = (index) => {
        if (!car?.Imagen) return;
        setExpandedImage(car.Imagen[index]);
        setExpandedImageIndex(index);
    };

    const sliderSettings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: car?.Imagen?.length > 1,
        autoplay: car?.Imagen?.length > 1,
        autoplaySpeed: 3000,
        dots: car?.Imagen?.length > 1,
        arrows: false,
        customPaging: (i) => (
            window.innerWidth >= 1024 ? (
                <div className="dotscontainer">
                    <img 
                        src={car.Imagen[i]} 
                        alt={`Thumbnail ${i + 1}`} 
                        className={`custom-dot ${i === activeImageIndex ? 'active' : ''}`} 
                    />
                </div>
            ) : (
                <div className="custom-dot"></div>
            )
        ),
        beforeChange: (current, next) => {
            setActiveImageIndex(next);
        }
    };
    

  return (
    <div className="CarDetail-Component" id='detail'>
      <div className="CarDetail-Container">
        <div className='Back-Button-Container'>
            <button onClick={CarsClick}><IoMdArrowRoundBack /></button>
        </div>
        <div className='Info-Basica-Container'>
            <div className='Slider-Img-Container'>
                <div className="Image-Detail-Slider">
                    <Slider {...sliderSettings}>
                        {car.Imagen.map((imagen, index) => (
                        <div key={index} className="slider-image" onClick={() => handleExpandImage(index)}>
                            <img className="CarDetail-Img" src={imagen} alt={`Imagen ${index + 1}`} />
                        </div>
                        ))}
                    </Slider>
                </div>
            </div>

        {isModalOpen && expandedImage && (
            <div className="Expanded-Image-Overlay" onClick={handleCloseImage}>
                <div className='Expanded-Container'>
                    <div className="Expanded-Image-Container" onClick={(e) => e.stopPropagation()}>
                        <button className="Prev-Button" onClick={handlePrevImage}>❮</button>
                        <img src={expandedImage} alt="Imagen ampliada" className="Expanded-Image" />
                        <button className="Next-Button" onClick={handleNextImage}>❯</button>
                    </div>

                    {window.innerWidth >= 1024 ? (
                    <div className="Thumbnail-Container">
                        {car.Imagen.map((image, index) => (
                        <img
                            key={index}
                            src={image}
                            alt={`Thumbnail ${index}`}
                            className={`Thumbnail ${index === expandedImageIndex ? 'active' : ''}`}
                            onClick={(e) => {
                            e.stopPropagation();
                            handleThumbnailClick(index);
                            }}
                        />
                        ))}
                    </div>
                    ) : (
                    <div className="Dots-Container">
                        {car.Imagen.map((_, index) => (
                            <span
                                key={index}
                                className={`Dot ${index === expandedImageIndex ? 'active' : ''}`}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleThumbnailClick(index);
                                }}
                            ></span>
                        ))}
                    </div>
                    )}
                </div>
                <div className='Back-Counter-Container'>
                    <div className="Image-Counter">
                        {expandedImageIndex + 1}/{car.Imagen.length}
                    </div>
                    
                    <button className="Close-Button" onClick={handleCloseImage}><IoMdArrowRoundBack /></button>
                </div>
            </div>
        )}

            
            <div className='Info-Basica-1'>
                <h2 className='CarDetail-Name'>{car.Marca} {car.Modelo}</h2>
                <div className='Basic-Content-container'>
                    <div className='Basic-Content-1'>
                        <p>{car.Ano}</p>
                        <p>•</p>
                        <p>{car.Kilometros} Km</p>
                        <p>•</p>
                        <p>{car.Radicada}</p>
                    </div>
                </div>
                
                <div className='Precio-Auto-Container'>
                    <div>
                        <p>{car.Precio}</p>
                    </div>                    
                </div>


                {/* General */}
                <div className='All-Info-Container'>
                    <h5 onClick={() => toggleVisibility(setIsGeneralVisible)}>
                        General
                        <span className={`arrow ${isGeneralVisible ? 'up' : 'down'}`}></span>
                    </h5>
                    {isGeneralVisible && (
                        <div>
                            <div className='All-info-1'>
                                <div>
                                    <h6>Numero de Velocidades</h6>
                                    <p>{car.Velocidades || "No especificado"}</p>
                                </div>
                                <div>
                                    <h6>Transmision</h6>
                                    <p>{car.Transmision || "No especificado"}</p>
                                </div>
                            </div>
                            <div className='All-info-2'>
                                <div>
                                    <h6>Traccion</h6>
                                    <p>{car.Traccion || "No especificado"}</p>
                                </div>
                                <div>
                                    <h6>Tipo de Combustible</h6>
                                    <p>{car.Combustible || "No especificado"}</p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                {/* Seguridad */}
                <div className='All-Info-Container'>
                    <h5 onClick={() => toggleVisibility(setIsSecurityVisible)}>
                        Seguridad
                        <span className={`arrow ${isSecurityVisible ? 'up' : 'down'}`}></span>
                    </h5>
                    {isSecurityVisible && (
                        <div>
                            <div className='All-info-1'>
                                <div>
                                    <h6>Airbag</h6>
                                    <p>{car.Airbag || "No especificado"}</p>
                                </div>
                                <div>
                                    <h6>Frenos ABS</h6>
                                    <p>{car.Abs || "No especificado"}</p>
                                </div>
                            </div>

                            <div className='All-info-2'>
                                <div>
                                    <h6>Sensor de Distancia</h6>
                                    <p>{car.SensorDistancia || "No especificado"}</p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                {/* Interior y Confort */}
                <div className='All-Info-Container'>
                    <h5 onClick={() => toggleVisibility(setIsComfortVisible)}>
                        Interior y Confort
                        <span className={`arrow ${isComfortVisible ? 'up' : 'down'}`}></span>
                    </h5>
                    {isComfortVisible && (
                        <div>
                            <div className='All-info-1'>
                                <div>
                                    <h6>Asientos</h6>
                                    <p>{car.Asientos || "No especificado"}</p>
                                </div>
                                <div>
                                    <h6>Aire Acondicionado</h6>
                                    <p>{car.Aire || "No especificado"}</p>
                                </div>
                            </div>
                            <div className='All-info-2'>
                                <div>
                                    <h6>Radio</h6>
                                    <p>{car.Radio || "No especificado"}</p>
                                </div>
                                <div>
                                    <h6>Bluetooth</h6>
                                    <p>{car.Bluetooth || "No especificado"}</p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                {/* Dimensiones y Carrocería */}
                <div className='All-Info-Container'>
                    <h5 onClick={() => toggleVisibility(setIsDimensionsVisible)}>
                        Dimensiones y Carrocería
                        <span className={`arrow ${isDimensionsVisible ? 'up' : 'down'}`}></span>
                    </h5>
                    {isDimensionsVisible && (
                        <div>
                            <div className='All-info-1'>
                                <div>
                                    <h6>Color</h6>
                                    <p>{car.Color || "No especificado"}</p>
                                </div>
                                <div>
                                    <h6>Carrocería</h6>
                                    <p>{car.Carroceria || "No especificado"}</p>
                                </div>
                            </div>
                            <div className='All-info-2'>
                                <div>
                                    <h6>Puertas</h6>
                                    <p>{car.Puertas || "No especificado"}</p>
                                </div>
                                <div>
                                    <h6>Cilindrada (Litros)</h6>
                                    <p>{car.Litros || "No especificado"}</p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                {/* Performance y Dirección */}
                <div className='All-Info-Container'>
                    <h5 onClick={() => toggleVisibility(setIsPerformanceVisible)}>
                        Performance y Dirección
                        <span className={`arrow ${isPerformanceVisible ? 'up' : 'down'}`}></span>
                    </h5>
                    {isPerformanceVisible && (
                        <div>
                            <div className='All-Direc-info-1'>
                                <div>
                                    <h6>Dirección Asistida</h6>
                                    <p>{car.Direccion || "No especificado"}</p>
                                </div>
                                <div>
                                    <h6>Número de Velocidades</h6>
                                    <p>{car.Velocidades || "No especificado"}</p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <div className='Consulta-Button-Container'>
                    <button onClick={handleWhatsappClick}>Consultar por el Auto</button>
                </div>
            </div>
        
        </div>
      </div>
    </div>
  );
};

export default CarDetail;
