import "./landing.css";
import React from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import Img1 from '../../Assets/images/Landing/Landing-3.jpg';
import Img2 from '../../Assets/images/Landing/Landing-2.jpg';
import Img3 from '../../Assets/images/Landing/Landing-1.png';


const Landing = () => {

  const navigate = useNavigate();
  const location = useLocation();
  
   const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
  };
  
  const handleNavigation = (path, sectionId) => {
    if (location.pathname === path) {
      scrollToSection(sectionId);
    } else {
      navigate(path);
      setTimeout(() => scrollToSection(sectionId), 100);
    }
  };

    return (
      <div className="Landing-Component">
        <div className="Landing-Container">
          <div className="information-container">
            <div className="Title-container">
              <h1 className="Title">
                <span>Tu auto nuevo,</span>
                <br />
                al mejor precio.
              </h1>

              <p>
                En <span style={{ color: '#001CAA' }}>DinCar</span> aceptamos tu auto
                <br />
                como parte de pago y te ofrecemos
                <br />
                las mejores opciones.
                <br />
                ¡Veni y maneja el cambio hoy!
              </p>
              
            </div>

            <div class="img-container">
              <div class="img-landing-1">
                <img src={Img1} alt="" />
              </div>
              
              <div class="img-landing-2">
                <img src={Img2} alt="" />
              </div>
              
              <div class="img-landing-3">
                <img src={Img3} alt="" />
              </div>
            </div>

          </div>
          <div className="ButtonStartLanding-Content" >
            <button className="ButtonStartLanding" onClick={() => handleNavigation('/nuestros-autos', 'Cars')} >ENCONTRA TU AUTO</button>
          </div>
        </div>
      </div>
    );
};

export default Landing;