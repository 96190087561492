import './quienessomos.css';
import React from 'react';
import Img from '../../Assets/images/Car-image-wall.png';



const QuienSomos = () => {
  return (
    <div className="QuienSomos-Component">
      <div className="QuienSomos-Container">
        <div className='QuienSomos-Info-Container'>    
          <h2>Quienes <span>Somos?</span></h2>
            
          <p>
            Somos una empresa familiar que, desde hace más de 22 años, se dedica a ofrecer vehículos usados cuidadosamente 
            <br/>
            seleccionados para garantizar calidad y fiabilidad.
            <br/>
            <br/>
            Cada vehículo es inspeccionado rigurosamente para asegurarnos de que cumpla con los estándares que nuestros clientes esperan.
            <br/>
            <br/>
            En DinCar, nuestro compromiso es simple: ofrecerte una experiencia transparente, cercana y confiable.
            <br/> 
            <br/>
            Estamos aca para asesorarte en cada paso del camino, desde elegir el auto adecuado hasta asegurarte de que 
            <br/>
            te sientas completamente satisfecho con tu elección.
          </p>   
        </div> 

        <div className='QuienSomos-Image-Container'>
          <img alt=''  src={Img} />
        </div>
      </div>
    </div>
  );
};

export default QuienSomos;
