import './loading.css';
import React from 'react';
import Car from '../../Assets/images/Loading/loading.png';


const Loading = () => {
  return (
    <div className="Loading">
     
      <img className="Load1" src={Car} alt="" />
      
      <img className="Load2" src={Car} alt="" />


      <img className="Load3" src={Car} alt="" />
     
    </div>
  );
};

export default Loading;
