import "./contact.css";
import React, { useState } from "react";
import emailjs from "emailjs-com";
import { animateScroll as scroll } from "react-scroll";
import SuccessPopUp from "./SuccessPopUp";
import { ClipLoader } from "react-spinners";
import { FaFacebookSquare } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { FaInstagram, FaLocationDot, FaWhatsapp } from "react-icons/fa6";
import { SiGmail } from "react-icons/si";



const Contact = () => {

  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showError, setShowError] = useState(false);
  const [missingFields, setMissingFields] = useState([]);
  const [loading, setLoading] = useState(false);

  const isFormValid = email.trim() !== "" && message.trim() !== "";
  const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isFormValid && isValidEmail) {
      setLoading(true);
      const templateParams = {
        from_email: email,
        message: message,
        to_email: "dincar.info@gmail.com",
        subject: "Consulta web",
      };

      emailjs
        .send(
          process.env.REACT_APP_SERVICE_ID,
          process.env.REACT_APP_TEMPLATE_ID,
          templateParams,
          process.env.REACT_APP_USER_ID
        )
        .then(
          (response) => {
            console.log("Correo enviado exitosamente!", response.status, response.text);
            setLoading(false);
            setShowSuccessPopup(true);
            setEmail("");
            setMessage("");
            setMissingFields([]);
            setShowError(false);
          },
          (error) => {
            console.error("Error al enviar el correo:", error);
            setLoading(false);
          }
        );
    } else {
      setShowError(true);
      setMissingFields([
        ...(email.trim() === "" ? ["email"] : []),
        ...(message.trim() === "" ? ["message"] : []),
      ]);
    }
  };

  const handleTextareaChange = (e) => {
    setMessage(e.target.value);
  };

  const handleCloseSuccessPopup = () => {
    setShowSuccessPopup(false);
  };

  const HandleClickUbication = () => {
    window.open('https://maps.app.goo.gl/vMaZGwNHbmuu3YoM9')
  }

  const HandleClickInstagram = () => {
    window.open('https://www.instagram.com/dincar_ok')
  }

  const HandleClickFacebook = () => {
    window.open('https://www.facebook.com/profile.php?id=61572393967889')
  }

  const handleClickWhatsapp = () => {
    window.open(
      "https://wa.me/5491124096499?text=Hola%20DinCar.%20Como%20estas?%20Queria%20consultarles%20por%20un%20auto%20que%20vi%20en%20su%20pagina.%20Gracias.%20"
    );
  };

  const mail = "dincar.info@gmail.com";
  const subject = "Consulta desde DinCar web";
  const body = "";

  const mailtoLink = `mailto:${mail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;



  return (
    <div id="contactPage" className="contact">
      <div className="contact-container">
        <div className="ContactTitlecontainer">
          <h2><span>Contactanos</span></h2>
          <p>
            Cualquier consulta para obtener más información sobre la compra de un autos, 
            no dudes en comunicarte con nosotros. 
            <br />
            <br />
            Estaremos encantados de escucharte y ayudarte a encontrar el que mejor se adapte a tus necesidades.
          </p>
        </div>

        <form className="form-contact" onSubmit={handleSubmit}>
          <div className={`input-container ${missingFields.includes("email") && "error"}`}>
            <input
              placeholder="MAIL"
              className="input-email"
              type="email"
              id="email"
              minLength="10"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className={`input-container ${missingFields.includes("message") && "error"}`}>
            <textarea
              className="textarea-m"
              placeholder="MENSAJE"
              id="message"
              required
              value={message}
              minLength="5"
              onChange={handleTextareaChange}
            />
          </div>

          {missingFields.length > 0 && (
            <div className="error-message">
              Por favor, complete todos los campos obligatorios.
            </div>
          )}

          <div className="submitbutton-pos">
            {!loading ? (
              <button className="button-submition" disabled={!isFormValid}>
                ENVIAR
              </button>
            ) : (
              <span className="button-Loading">
                <ClipLoader color="#ffffff" loading={loading} size={20} />
              </span>
            )}
          </div>
        </form>

        {showSuccessPopup && <SuccessPopUp onClose={handleCloseSuccessPopup} />}
      </div>

      <footer className="Footercontact">
        <div className="Info-footer-Container">
          <div className="Phone-Ubication-container">
            <p onClick={ HandleClickUbication } style={{ textDecoration: 'underline', cursor: 'pointer' }}><FaLocationDot style={{ fontSize: '15px', marginRight: '2%' }}/> Av. los incas 5063, Parque Chas, CABA.</p>
            <p><FaPhoneAlt style={{ fontSize: '15px', marginRight: '2%' }}/> 4521-9862</p>
          </div>
          <div className="Horario-Container">  
            <p className="Horario-footer">Lunes a Viernes de 10hs - 18:30hs</p>
            <p className="Horario-footer">Sabados de 10hs - 15hs</p>
          </div>
          <a className="Etiqueta-A" href={mailtoLink} target="_blank" rel="noopener noreferrer"><SiGmail style={{ fontSize: '20px', marginRight: '2%' }}/> dincar.info@gmail.com</a>
        </div>
        <div className="icon-container-footer">
          <div className="Icons-Container">
            <FaInstagram style={{ cursor: 'pointer' }} onClick={ HandleClickInstagram }/>
            <FaFacebookSquare style={{ cursor: 'pointer' }} onClick={ HandleClickFacebook }/>
            <FaWhatsapp style={{ cursor: 'pointer' }} onClick={ handleClickWhatsapp }/>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Contact;
