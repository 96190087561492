import React from 'react';
import OurCars from '../../Components/5-Cars';

const OurCarsPage = () => {
  return (
    <div>
      <div id='Cars'>
        <OurCars/>
      </div>
    </div>
  );
};

export default OurCarsPage;
