import "./whatsappicon.css";
import React from "react";
import WappLogo from "../../Assets/images/whatsappLogo.webp";

const WhatsappIcon = () => {
  const handleClickWhatsapp = () => {
    window.open(
      "https://wa.me/5491124096499?text=Hola%20DinCar.%20Como%20estas?%20Queria%20consultarles%20por%20un%20auto%20que%20vi%20en%20su%20pagina.%20Gracias.%20"
    );
  };

  return (
    <div className="Whatsapp-container">
      <img
        className="Whatsapp-Logo"
        onClick={handleClickWhatsapp}
        alt=""
        src={WappLogo}
      />
    </div>
  );
};

export default WhatsappIcon;
