import { useLocation, useNavigate } from 'react-router-dom';
import "./nuevosingresoscard.css"
import React, { useEffect } from "react";
import { slugify } from '../../../Helpers/slugify';

const NuevosIngresosCard = ({ car }) => {   
    const handleNavigation = (car, sectionId) => {
        const slug = slugify(`${car.Marca}-${car.Modelo}`, { lower: true });
        const path = `/auto/${slug}`;
      
        if (location.pathname === path) {
          scrollToSection(sectionId);
        } else {
          navigate(path, { state: { id: car.id } });
          const interval = setInterval(() => {
            const section = document.getElementById(sectionId);
            if (section) {
              clearInterval(interval);
              section.scrollIntoView({ behavior: 'smooth' });
            }
          }, 100);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    const navigate = useNavigate();
    const location = useLocation();

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className="Rolecard-container">
            <div className="Rolecard-information">
                <img 
                    onClick={() => handleNavigation(car, 'Detail')}
                    className="Cars-Image" 
                    src={car.ImagenPortada} 
                    alt={`${car.Marca} ${car.Modelo}`} 
                />
                <h4 className="executives-title">{car.Marca} • {car.Modelo}</h4>
                <h5>{car.Ano} • {car.Kilometros}Km • {car.Transmision}</h5>
            </div>
            
            <div className="Precio-New-Card">
                <p>{car.Precio}</p>
            </div>

            <div className="Button-New-Card">
                <button onClick={() => handleNavigation(car, 'Detail')}>
                    Ver Más
                </button>
            </div>
        </div>
    );
};

export default NuevosIngresosCard;
