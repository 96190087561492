import React from 'react';
import CarDetail from '../../Components/6-CarDetail';

const DetailsPage = () => {
  return (

    

    <div>
      <div id='Detail'>
        <CarDetail/>
      </div>
    </div>
  );
};

export default DetailsPage;
